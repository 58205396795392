import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    apiUrls: {
      tasks: process.env.VUE_APP_BACK_URL + 'wp-json/tasks/v1/post',
      plans: process.env.VUE_APP_BACK_URL + 'wp-json/plans/v1/post',
      auth: process.env.VUE_APP_BACK_URL + 'wp-json/jwt-auth/v1/token',
    },
    tasksByCat: {},
    tasks: [],
    tasksLoaded: false,
    currentPlan: {
      name: 'Nom du client',
      tasks: [],
      ID: null,
      email: 'adresse@email.fr',
      tel: '0200000000',
      iban: 'iban',
      author: 'NOM',
      creationDate: Date.now(),
      lastChange: Date.now(),
      type: 'plan-de-com',
      intro: '',
      address: 'adresse du client'
    },
    isCurrentPlanUpToDate: false,
    plans: [],
    steps: [],
    generatingPDF: false,
    style: 'ese'
  },

  mutations: {
    SET_USER(state, user) {
      state.user = user;

      if (!state.currentPlan.ID) {
        state.currentPlan.author = user.user_display_name;
      }
    },
    DELETE_USER(state) {
      state.user = null
    },
    STORE_TASKS(state, tasks) {
      state.tasks = tasks;
      state.tasksLoaded = true;
    },
    STORE_PLANS(state, plans) {
      state.plans = plans;
    },
    STORE_TASKS_BY_CAT(state, tasksByCat) {
      state.tasksByCat = tasksByCat;
    },
    SET_CURRENT_PLAN(state, plan) {
      state.currentPlan = plan;
    },
    SET_PLAN_INFO(state, info) {
      state.currentPlan.name = info.name;
      state.currentPlan.email = info.email;
      state.currentPlan.tel = info.tel;
      state.currentPlan.iban = info.iban;
      state.currentPlan.intro = info.intro;
      state.currentPlan.address = info.address;
    },

    SET_PLAN_TASKS(state, planTasks) {
      state.currentPlan.tasks = state.currentPlan.tasks.concat(planTasks);
    },

    CLEAR_PLAN(state) {
      state.currentPlan = {
        name: 'Nom du client',
        tasks: [],
        ID: null,
        email: 'adresse@email.fr',
        tel: '0200000000',
        iban: 'iban',
        author: state.user.user_display_name,
        creationDate: Date.now(),
        lastChange: Date.now(),
        type: 'plan-de-com',
        intro: '',
        address: 'adresse du client'
      };
    },

    UPDATE_PLAN_TASK(state, { index, values }) {
      Vue.set(state.currentPlan.tasks, index, values);
      state.currentPlan.tasks.splice(index, 1, values);
    },

    SET_CURRENT_PLAN_ID(state, id) {
      state.currentPlan.ID = id;
    },

    SET_CURRENT_PLAN_AUTHOR(state) {
      if (state.user?.user_display_name) {
        state.currentPlan.author = state.user.user_display_name;
      }
    },

    SET_CURRENT_PLAN_TYPE(state, type) {
      state.currentPlan.type = type;
    },

    UPDATE_LASTCHANGE(state) {
      state.currentPlan.lastChange = Date.now();
      console.log('lastchange ' + state.currentPlan.lastChange)
    },
    ADD_STEPS(state, steps) {
      steps.forEach(step => {
        if (!state.steps.includes(step.name)) {
          state.steps.push(step.name);
        }
      });
      state.steps.sort();
    },

    REMOVE_PLAN_TASK(state, index) {
      // deep array copy
      const newCurrentPlan = JSON.parse(JSON.stringify(state.currentPlan));
      newCurrentPlan.tasks.splice(index, 1);
      delete state.currentPlan;
      Vue.set(state, 'currentPlan', newCurrentPlan);
    },

    SET_PDF(state, generatingPDF) {
      state.generatingPDF = generatingPDF;
    },
    
    SET_PLAN_UPTODATE(state, upToDate) {
      state.isCurrentPlanUpToDate = upToDate;
    },

    SET_STYLE(state, style) {
      state.style = style;
    }
  },

  actions: {
    login({ commit, dispatch, state }, payload) {
      return new Promise(async (resolve, reject) => {
        try {
          const { data } = await axios.post(this.state.apiUrls.auth, payload)
          commit('SET_USER', data);
          dispatch('saveUser', data);
          resolve(data)
        } catch (e) {
          reject(e)
        }
      })
    },

    validate({ state }) {
      return axios({
        url: this.state.apiUrls.auth + '/validate',
        method: 'post',
        headers: {
          'Authorization': `Bearer ${state.user.token}`
        }
      })
    },

    deconnection({ commit }) {
      commit('DELETE_USER');
      localStorage.removeItem('current-user');
      router.push({ path: '/login' });
    },

    updateDatabase({ state }) {
      let url = process.env.VUE_APP_BACK_URL + '/wp-json/wp/v2/plan';
      if (state.currentPlan.ID) {
        url += '/' + state.currentPlan.ID;
      }
      const content = {
        info: {
          email: state.currentPlan.email,
          tel: state.currentPlan.tel,
          iban: state.currentPlan.iban,
          author: state.currentPlan.author,
          creationDate: state.currentPlan.creationDate,
          lastChange: state.currentPlan.lastChange,
          type: state.currentPlan.type,
          intro: state.currentPlan.intro,
          address: state.currentPlan.address,
        },
        tasks: state.currentPlan.tasks
      };


      const config = {
        method: "POST",
        url,
        data: {
          "title": state.currentPlan.name,
          "status": "publish",
          "content": JSON.stringify(content),
        },
        headers: {
          // 'content-type': 'application/json',
          Authorization: `Bearer ${state.user.token}`,
        },
      };
      console.log(content);

      return axios(config);
    },

    fetchTasks({ commit, state }) {
      fetch(state.apiUrls.tasks)
        .then((r) => r.json())
        .then((res) => {

          // Store all tasks
          commit('STORE_TASKS', res);

          // Store tasks sorted by cat
          const tasksByCat = {};
          res.forEach(task => {

            if (!task.categories) {
              task.categories = [{ name: 'Non classés' }];
            }
            if (!task.steps) {
              task.steps = [{ name: 'Non classés' }];
            }

            commit('ADD_STEPS', task.steps);

            [...task.categories].forEach(cat => {
              if (!Object.keys(tasksByCat).includes(cat.name)) {
                tasksByCat[cat.name] = [];
              }
              tasksByCat[cat.name].push(task);
            });
          });
          commit('STORE_TASKS_BY_CAT', tasksByCat);
        });
    },

    fetchPlans({ commit, state }) {
      fetch(state.apiUrls.plans)
        .then((r) => r.json())
        .then((res) => {
          // console.log(res);
          // Store plans
          commit('STORE_PLANS', res);
        });
    },

    addToPlan({ commit, dispatch, state }, ids) {
      const planTasks = [];
      ids.forEach(id => {
        // retrieve the task to add
        const taskToAdd = state.tasks.find(task => {
          if (state.currentPlan.tasks.find(task => task.ID === parseInt(id))) {
            return false;
          }
          return task.ID === parseInt(id)
        });

        if (taskToAdd) {
          // generates unique id
          const uniqueId = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
          );
          // console.log(taskToAdd.steps)

          const subscription = typeof taskToAdd.acf?.subscription !== 'undefined'
            ? taskToAdd.acf?.subscription : 0;

          planTasks.push({
            ID: uniqueId,
            title: taskToAdd.post_title,
            description: taskToAdd.acf.description,
            descriptionDev: taskToAdd.acf.description_dev,
            duration: taskToAdd.acf.duration,
            cats: taskToAdd.categories.map((cat) => cat.name),
            steps: taskToAdd.steps.map((step) => step.name),
            img: taskToAdd.acf?.img,
            url: taskToAdd.acf?.url,
            subscription,
          });
        }
      });
      if (planTasks.length > 0) {
        commit('SET_PLAN_TASKS', planTasks);
        commit('SET_PLAN_UPTODATE', false);
        commit('UPDATE_LASTCHANGE');
        dispatch('savePlan');
      }
    },

    updatePlanTask({ commit, dispatch, state }, values) {
      const index = state.currentPlan.tasks.findIndex(task => task.ID === values.ID);
      if (index !== -1) {
        commit('UPDATE_PLAN_TASK', { index, values });
        commit('SET_PLAN_UPTODATE', false);
        commit('UPDATE_LASTCHANGE');
        dispatch('savePlan');
      }
    },

    removeFromPlan({ commit, dispatch, state }, id) {
      const index = state.currentPlan.tasks.findIndex(task => task.ID === id);
      if (index !== -1) {
        commit('REMOVE_PLAN_TASK', index);
        commit('SET_PLAN_UPTODATE', false);
        commit('UPDATE_LASTCHANGE');
        dispatch('savePlan');
      }
    },

    setCurrentPlan({ commit, state }, payload) {
      const wp_plan = state.plans.find(plan => plan.ID === payload.planId);
      if (wp_plan) {
        let content = null;
        if (wp_plan.post_content) {
          content = JSON.parse(wp_plan.post_content);
        }

        const plan = {
          name: wp_plan.post_title,
          tasks: content.tasks,
          ID: wp_plan.ID,
          email: content.info.email,
          tel: content.info.tel,
          iban: content.info.iban,
          author: content.info.author,
          creationDate: content.info.creationDate,
          lastChange: content.info.lastChange,
          type: content.info.type,
          intro: content.info.intro,
          address: content.info.address,
        };
        commit('SET_CURRENT_PLAN', plan);
        if (payload.redirect) {
          router.push({ path: '/' });
        }
      }
    },

    setCurentPlanID({ commit, dispatch }, id) {
      commit('SET_CURRENT_PLAN_ID', id);
      dispatch('savePlan');
    },

    updatePlanInfo({ commit, dispatch, state }, info) {
      commit('SET_PLAN_INFO', info);
      commit('SET_PLAN_UPTODATE', false);
      commit('UPDATE_LASTCHANGE');
      dispatch('savePlan');
    },

    autoLoadPlan({ commit }) {
      const plan = localStorage.getItem("current-plan");
      if (plan && plan !== 'undefined') {
        commit('SET_CURRENT_PLAN', JSON.parse(plan));
        console.log("Plan loaded from localstorage");
      } else {
        commit('SET_CURRENT_PLAN_AUTHOR');
      }
    },

    autoLoadUser({ commit }) {
      const user = localStorage.getItem('current-user');

      if (user) {
        const parsedUser = JSON.parse(user);
        const today = new Date().getDate();
        const saveDate = new Date(parsedUser.saveDate).getDate();

        if (today === saveDate) {
          commit('SET_USER', parsedUser);
          console.log("User loaded from localstorage");
          router.push({ path: '/' });
        }
        else {
          localStorage.removeItem('current-user');
          localStorage.removeItem('current-plan');
        }
      }
    },

    updatePlanType({ commit, dispatch }, type) {
      commit('SET_CURRENT_PLAN_TYPE', type);
      commit('UPDATE_LASTCHANGE');
      dispatch('savePlan');
    },

    planUpToDate({ commit }, upToDate) {
      commit('SET_PLAN_UPTODATE', upToDate);
    },

    savePlan({ state }) {
      localStorage.setItem("current-plan", JSON.stringify(state.currentPlan));
      console.log("Plan saved in localstorage");
    },

    saveUser({ state }) {
      const user = state.user;
      // store save date 
      user.saveDate = new Date();
      localStorage.setItem("current-user", JSON.stringify(user));
      console.log("User saved in localstorage");
    },

    startGeneratePDF({ commit }) {
      commit('SET_PDF', true);
    },

    stopGeneratePDF({ commit }) {
      commit('SET_PDF', false);
    },

    switchStyle({commit, state}) {
      const newStyle = state.style === 'ese' ? 'plune' : 'ese';
      commit('SET_STYLE', newStyle);
    }
  },

  getters: {
    tasks: state => state.tasks,
    tasksByCat: state => state.tasksByCat,
    currentPlan: state => state.currentPlan,
    plans: state => state.plans,
    tasksLoaded: state => state.tasksLoaded,
    apiUrls: state => state.apiUrls,
    user: state => state.user,
    steps: state => state.steps,
    generatingPDF: state => state.generatingPDF,
    upToDate: state => state.isCurrentPlanUpToDate,
    style: state => state.style,
  }
})
