<template>
  <div id="app" :class="{'style-ese': style === 'ese', 'style-plune': style === 'plune'}">
    <Header />
    <transition mode="out-in" name="wipe" appear>
      <router-view />
    </transition>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";

export default {
  components: {
    Header,
  },
  computed: {
    style() {
      return this.$store.getters.style;
    },
  },
  created() {
    if (!this.$store.getters.user) {
      this.$store.dispatch("autoLoadUser");
      this.$store.dispatch("autoLoadPlan");
    }
  },
};
</script>

<style lang="scss">
@import "sass/app";

h1,
h2 {
  color: $purple;
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 15px;
}

#app {
  padding-top: 50px;
}

.select2-container--default .select2-results>.select2-results__options {
  max-height: calc(100vh - 250px);
}
</style>
