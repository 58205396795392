<template>
  <div class="plan">
    <div class="display plan-header" v-if="displayMode">
      <div>
        <h2>{{ currentPlan.name }}</h2>
        <button class="btn" @click="editInfo" title="Modifier">
          <i class="fas fa-pen-alt"></i>
        </button>
        <button v-if="planSteps.length > 0" class="btn" @click="generatePDF" title="Générer le PDF">
          <i class="fas fa-file-pdf"></i>
        </button>
      </div>
      <!-- <div class="type">
        <div class="type__group">
          <input type="radio" id="plan-de-com" value="plan-de-com" name="type" v-model="type" />
          <label for="plan-de-com">Plan de com</label>
        </div>
        <div class="type__group">
          <input type="radio" id="devis" value="devis" name="type" v-model="type" />
          <label for="devis">Devis</label>
        </div>
      </div> -->
      <div class="plan__info">
        <div v-if="currentPlan.address" id="plan__address" class="plan__address">{{ currentPlan.address }}</div>
        <div>{{ currentPlan.email }} / {{ currentPlan.tel | tel }} <span v-if="currentPlan.iban">/ IBAN : {{
        currentPlan.iban
        }}</span></div>
        <!-- <div v-if="currentPlan.type === 'devis'"> -->
        <a href="https://pay.gocardless.com/flow/RE001GKR1H1YM3VRN7DFXPSCEW5E6EQD" target="_blank">Go Cardless</a>
        <!-- </div> -->
      </div>
      <div v-if="currentPlan.intro" id="plan__intro" class="plan__intro" v-html="currentPlan.intro"></div>
    </div>

    <div class="edit plan-header" v-else>
      <div class="edit-group">
        <label for="plan-title">Nom du client<br />
          <input type="text" id="plan-title" v-model="temp.name" />
        </label>
      </div>
      <div class="edit-group w33">
        <label for="plan-title">E-mail<br />
          <input type="text" id="plan-email" v-model="temp.email" />
        </label>
      </div>
      <div class="edit-group w33">
        <label for="plan-title">Téléphone<br />
          <input type="text" id="plan-tel" v-model="temp.tel" />
        </label>
      </div>
      <div class="edit-group w33">
        <label for="plan-title">IBAN<br />
          <input type="text" id="plan-iban" v-model="temp.iban" minlength="14" maxlength="34" />
        </label>
      </div>
      <div class="edit-group w50">
        <label for="plan-title">Introduction<br />
          <textarea rows="4" id="plan-intro" v-model="temp.intro"></textarea>
        </label>
      </div>
      <div class="edit-group w50">
        <label for="plan-title">adresse<br />
          <textarea rows="4" id="plan-address" v-model="temp.address"></textarea>
        </label>
      </div>
      <div class="edit__buttons">
        <button class="btn btn-cancel" @click="cancel" title="Annuler">
          <i class="fas fa-times"></i>
        </button>
        <button class="btn btn-save" @click="saveInfo" title="Valider">
          <i class="fas fa-check"></i>
        </button>
      </div>
    </div>

    <div id="element-to-pdf" :class="{ 'printing': generatingPDF, 'editing': !generatingPDF }">
      <div class="cover-page">
        <img src="@/assets/cover-page.jpg" alt="" />
        <div class="infos">
          <div class="infos__name">{{ currentPlan.name }}</div>
          {{ currentPlan.email }}<br>
          {{ currentPlan.tel | tel }}<br>
          <div class="infos__address" style="white-space: pre;" v-html="currentPlan.address"></div>
        </div>
      </div>

      <div v-for="(step, index) in planSteps" :key="step.key" class="step-tasks">
        <h3 class="step-title">{{ index + 1 }}. {{ step.name }}</h3>
        <ul>
          <Task v-for="task in step.tasks" :key="task.ID" :task="task" :pageBreak="task.pageBreak" />
        </ul>
      </div>

      <div v-if="currentPlan.tasks.length" class="summary">
        <h2>Votre commande <button v-if="planSteps.length > 0 && !generatingPDF" class="btn" @click="generatePDF"
            title="Générer le PDF">
            <i class="fas fa-file-pdf fa-beat" style="--fa-beat-scale: 1.5;"></i>
          </button>
        </h2>

        <div class="table">
          <div class="line line--top">
            <div class="col col--title"><span class="text">Détail</span></div>
            <div class="col col--days"><span class="text">Nombre de jours</span></div>
            <div class="col col--subscription"><span class="text">Abonnement</span></div>
          </div>

          <div v-for="(step, index) in planSteps" :key="step.key">
            <div class="line" v-for="task in step.tasks" :key="task.ID">
              <div class="col col--title"><a class="text" :href="'#' + task.ID">{{ task.title }}</a></div>
              <div class="col col--days"><span class="text">
                {{ task.duration !== 0 ? task.duration.replace('.', ',') : task.duration }}
                </span></div>
              <div class="col col--subscription"><span class="text">{{ task.subscription }}</span></div>
            </div>
          </div>

          <div class="line line--total">
            <div class="col col--title"><span class="text">Total</span></div>
            <div class="col col--days"><span class="text">{{ duration | days }}</span></div>
            <div class="col col--subscription"><span class="text">{{ planSubscription | daysPerYear}}</span></div>
          </div>
        </div>

      </div>
    </div>
    <div class="pdf-loader" v-if="generatingPDF">
      <i class="fa-duotone fa-cog fa-spin"></i>
      Génération du PDF en cours...
    </div>

  </div>
</template>

<script>
import html2pdf from 'html2pdf.js'

import Task from "@/components/Task.vue";
import { mapGetters } from "vuex";

export default {
  name: "Plan",
  components: {
    Task,
  },
  data() {
    return {
      temp: {
        title: "",
      },
      displayMode: true,
    };
  },
  filters: {
    tel(val) {
      return val.replace(/.{1,2}(?=(.{2})+$)/g, '$& ');

    },
    days(val) {
      const day = val > 1 ? 'jours' : 'jour';
      const newVal = `${val} ${day}`;
      return newVal.replace('.', ',');
    },
    daysPerYear(val) {
      if (val === 0) {
        return '-';
      }
      const day = val > 1 ? 'jours' : 'jour';
      const newVal = `${val} ${day}/an`;
      return newVal.replace('.', ',');
    }
  },

  computed: {
    ...mapGetters(['currentPlan', 'steps', 'generatingPDF']),
    durationTotal() {
      const total = this.currentPlan.tasks.reduce(
        (sum, task) => parseFloat(task.duration) + sum,
        0
      );
      return total > 28 ? total : total + '/28';
    },
    duration() {
      return this.currentPlan.tasks.reduce(
        (sum, task) => parseFloat(task.duration) + sum,
        0
      );
    },
    planSubscription() {
      return this.currentPlan.tasks.reduce(
        (sum, task) => parseFloat(task.subscription) + sum,
        0
      );
    },
    costTotal() {
      const cost = parseFloat(this.durationTotal) * 500;
      return cost;
    },
    planSteps() {
      const steps = [];
      if (this.currentPlan.tasks.length > 0) {
        this.currentPlan.tasks.forEach((task) => {
          const taskStep = task.steps[0];
          const step = steps.find((step) => step.name === taskStep);
          if (step === undefined) {
            steps.push({
              name: taskStep,
              key: steps.length,
              tasks: [task],
            });
          } else {
            step.tasks.push(task);
          }
        });
      }
      steps.sort((a, b) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );

      return steps;
    },

    type: {
      get() {
        return this.currentPlan.type;
      },
      set(value) {
        this.$store.dispatch("updatePlanType", value);
      },
    },
  },

  methods: {
    editInfo() {
      this.temp.name = this.currentPlan.name;
      this.temp.email = this.currentPlan.email;
      this.temp.tel = this.currentPlan.tel;
      this.temp.iban = this.currentPlan.iban;
      this.temp.intro = this.currentPlan.intro;
      this.temp.address = this.currentPlan.address;
      this.displayMode = false;
    },
    saveInfo() {
      if (this.temp.name == "") {
        this.temp.name = "Nom du client";
      }
      if (this.temp.email == "") {
        this.temp.email = "adresse@email.fr";
      }
      if (this.temp.tel == "") {
        this.temp.tel = "téléphone";
      }
      if (this.temp.iban == "") {
        this.temp.iban = "iban";
      }
      this.$store.dispatch("updatePlanInfo", this.temp);
      this.displayMode = true;
    },
    cancel() {
      this.displayMode = true;
    },
    generatePDF() {
      this.$store.dispatch("startGeneratePDF");

      const element = document.getElementById('element-to-pdf');
      const options = {
        margin: 0,
        filename: this.formatFileName(),
        // image: { type: 'jpeg', quality: 1 },
        image: { type: 'jpeg', quality: 0.98 },
        image: { type: 'jpeg', quality: 0.96 },
        html2canvas: {
          dpi: 300,
          letterRendering: true,
          useCORS: true,
          backgroundColor: '#ffffff',
          scale: 4
        },
        jsPDF: {
          unit: 'in',
          format: 'a4',
          orientation: 'landscape'
        },
        pagebreak: { after: '.page-break-after', before: '.page-break-before' }
        // pagebreak: { after: '.page-break-after', avoid: 'avoid-all' }
      }

      html2pdf().set(options).from(element).save().then(() => this.$store.dispatch("stopGeneratePDF"));
    },
    formatFileName() {
      const year = new Date().getFullYear();
      const name = this.currentPlan.name
        .toUpperCase()
        .trim()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '_')
        .replace(/^-+|-+$/g, '');

      return `PCOM-${year}-${name}.pdf`;
    },
    slugify(str) {
      return str
    }
  },
};
</script>


<style scoped lang="scss">
@import "../sass/base/variables";

.plan {
  flex-grow: 1;
  padding-right: 15px;
  position: relative;

  &__info {
    font-size: 11px;

    a {
      text-decoration: underline;

      &:hover {
        color: $purple;
      }
    }
  }

  &__intro {
    background-color: rgba($black, .15);
    font-size: 11px;
    padding: 8px;
    border-radius: 3px;
    margin: 10px 0;
  }

  @media (max-width: 1200px) {
    padding-right: 0;
  }
}

h2 {
  margin-bottom: 0;
  margin-right: 15px;
  display: inline-block;
}

.plan-header {
  border-bottom: 1px solid rgba($purple, .2);
  padding-bottom: 15px;
  margin-bottom: 15px;

  h2 {
    font-size: 30px;
    margin-right: 20px;

  }

}

.edit {
  background-color: rgba($black, 0.25);
  padding: 10px;
  padding-left: 15px;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;

  &__buttons {
    margin-top: 10px;
    text-align: right;
  }
}

.edit-group {
  width: 100%;
  padding: 0 10px;

  label,
  input,
  textarea {
    display: block;
    width: 100%;
  }

  &.w33 {
    width: 33.333%;
  }

  &.w50 {
    width: 50%;
  }
}

.step-tasks {
  margin-bottom: 40px;
}

.step-title {
  color: $purple;
  font-weight: 700;
  font-size: 2rem;
  margin-bottom: 15px;

  .style-plune & {
    color: $pink;
  }

}

.btn {
  color: $purple;
  margin-left: 5px;
  padding: 3px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  transition: all 0.15s ease;
  font-size: 15px;

  &:hover {
    color: $white;
    background-color: $purple;
  }

  .style-plune & {
    color: $pink;

    &:hover {
      color: $white;
      background-color: $pink;
    }
  }
}

.total {
  margin: 10px 0;

  &::before {
    content: "";
    display: block;
    height: 1px;
    width: 40px;
    background-color: gray;
    margin-bottom: 5px;
  }
}

.cost {
  opacity: 0.5;
  font-size: 0.8em;
}

.type {
  margin-bottom: 10px;

  &::after {
    content: "";
    display: block;
    height: 1px;
    width: 40px;
    background-color: gray;
    margin-top: 10px;
  }

  &__group {
    display: inline-block;
    font-size: 11px;

    >input {
      position: absolute;
      opacity: 0;

      &:checked+label::after {
        transform: scale(1);
      }

      &:focus+label::before {
        box-shadow: 0 0 0 0.25em #dce7f5;
        border-color: blue;
      }
    }

    >label {
      display: grid;
      grid-template-columns: 1em 1fr;
      grid-template-areas: "checkbox label";
      gap: 0.7em;
      font-weight: inherit;
      margin-right: 10px;

      &::before,
      &::after {
        content: "";
        grid-area: checkbox;
        display: block;
        margin-top: 0.3em;
        transition: all 0.15s ease;
        border-radius: 50%;
      }

      &::before {
        width: 0.8em;
        height: 0.8em;
        border: 1px solid $black;
      }

      &::after {
        width: calc(0.8em - 2px);
        height: calc(0.8em - 2px);
        background-color: $purple;
        position: relative;
        top: 2px;
        left: 2px;
        transform: scale(0);
      }
    }
  }
}

.pdf-loader {
  color: $purple;
  padding: 10px 15px;
  box-shadow: 0 0 10px rgba($black, .5);
  font-size: 12px;
  border-top-right-radius: 5px;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: $purple;
  color: $white;

  i {
    margin-right: 5px;
    font-size: 16px;
  }
}


#element-to-pdf {
  &.printing {
    .step-title {
      display: none;
    }
    .step-tasks {
      margin-bottom: 0;
    }

    .cover-page {
      height: 791px;
    }
  }
  .cover-page {
    width: 100%;
    // height: 791px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .infos {
      position: absolute;
      top: 5%;
      right: 5%;
      width: 29%;

      &__name {
        text-transform: uppercase;
        font-weight: 700;
        color: $purple;
        font-size: 20px;

        &::after {
          content: '';
          display: block;
          height: 1px;
          width: 45px;
          margin: 6px 0;
          background-color: $purple;

        }
      }

      &__address {
        margin-top: 6px;
      }

    }


    img {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  &.editing {

    .cover-page {
      display: none;
    }
  }

  .summary {
    $col-height: 50px;

    margin-top: 40px;
    padding: 0 30px;

    h2 {
      text-align: center;
      display: block;
      color: $green;

    }

    .col {
      padding: 5px;
      line-height: 20px;

      display: flex;
      align-items: center;

      &--title {
        width: 56%;

        &::before {
          content: '';
          display: block;
          background-color: $white;
          height: 40px;
          width: 40px;
          border-radius: 50%;
          margin-right: 15px;
        }
      }

      &--days {
        background: $purple;
        color: $white;
        width: 22%;
        text-align: center;
        justify-content: center;
      }

      &--subscription {
        width: 22%;
        text-align: center;
        justify-content: center;
      }
    }

    .line {
      display: flex;
      width: 100%;
      height: 50px;
      margin-bottom: 8px;
      background: rgba($purple, .15);
      border-top-left-radius: 25px;
      border-bottom-left-radius: 25px;
      font-size: 16px;

      &--top,
      &--total {
        border-radius: 0;
        font-weight: 700;
        font-size: 18px;

        .col {
          background-color: $white;
          color: $black;
        }
      }

      &--total {
        .col {
          color: $purple;

          &--title {
            justify-content: flex-end;
            text-transform: uppercase;
            padding-right: 15px;
          }

          &--days {
            background: rgba($white, .05);
          }
        }
      }
    }
  }

}
</style>