<template>
  <header class="header">
    <div class="header__left">
      <h1>Plans de communication</h1>
      <button v-if="user" class="header__item header__item--btn" @click="newPlan" title="Nouveau">
        <i class="fas fa-plus-square"></i>
      </button>
      <router-link v-if="user" to="/load" class="header__item header__item--btn" title="Charger..." tag="button"><i
          class="fas fa-pen-alt"></i></router-link>
      <button class="header__item header__item--btn" :class="{ saving: saving }" @click="save" v-if="saveEnabled"
        title="Enregistrer">
        <i class="fas fa-save"></i>
      </button>
      <div class="header__item save-state">
        <i class="fas fa-exclamation" v-if="!upToDate && saveEnabled" title="Modifications non sauvegardées !"></i>
      </div>
    </div>
    <!-- <div class="header__style-switcher">
      Style : <button @click="switchStyle" :class="{ active: style === 'ese' }">ESE</button> / <button
        @click="switchStyle" :class="{ active: style === 'plune' }">Plune</button>
    </div> -->
    <div class="header__right" v-if="user">
      <span class="header__item header__item--user">Bonjour {{ user.user_display_name }}</span>
      <span class="header__item header__item--btn">
        <a :href="backUrl" title="Accéder au backoffice" target="_blank"><i
            class="fa-regular fa-database"></i></a></span>
      <button class="header__item header__item--btn" @click="deconnection" title="Déconnexion">
        <i class="far fa-power-off"></i>
      </button>
    </div>
  </header>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "Header",
  data() {
    return {
      saving: false,
      // backUrl: process.env.VUE_APP_BACK_URL + "wp-admin?"// + this.user.user_email,
    };
  },
  computed: {
    currentPlan() {
      return this.$store.getters.currentPlan;
    },
    plans() {
      return this.$store.getters.plans;
    },
    saveEnabled() {
      return this.$route.name === "Home" && this.currentPlan.tasks.length > 0;
    },
    upToDate() {
      return this.$store.getters.upToDate;
    },
    user() {
      return this.$store.getters.user;
    },
    style() {
      return this.$store.getters.style;
    },
    backUrl() {
      return process.env.VUE_APP_BACK_URL + "wp-login.php?user_email=" + this.user.user_email
    }
    // saveBtnText() {
    //   return this.currentPlan.ID ? "mettre à jour" : "sauver";
    // },
  },
  methods: {
    // deconnection() {
    //   this.$store.dispatch("deconnection");
    // },
    ...mapActions([
      'deconnection',
      'updateDatabase',
      'setCurentPlanID',
      'planUpToDate',
      'switchStyle'
    ]),
    save() {
      this.saving = true;
      this.updateDatabase()
        .then((res) => {
          const returnedID = res.data.id;
          if (this.currentPlan.ID !== returnedID) {
            this.setCurentPlanID(returnedID);
          }
          this.planUpToDate(true);
          this.saving = false;
        })
        .catch((err) => {
          console.log("erreur header save: " + err);
          this.saving = false;
        });
    },
    newPlan() {
      this.$store.commit("CLEAR_PLAN");
      localStorage.setItem("current-plan", JSON.stringify(this.plan));
      if (this.$route.name !== "Home") {
        this.$router.push({ path: "/" });
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../sass/base/variables";

h1 {
  color: $green;
  display: inline-block;
  font-size: 1.6rem;
  margin-bottom: 0;
  margin-right: 25px;

  .style-plune & {
    color: $white;
  }
}

.header {
  position: fixed;
  width: 100vw;
  height: 50px;
  top: 0;
  left: 0;
  background-color: $purple;
  color: $green;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1052;

  .style-plune & {
    background-color: $pink;
    color: $white;
  }


  &__left {
    padding-left: 25px;
  }

  &__right {
    padding-right: 25px;
  }

  &__item {
    background-color: transparent;
    border: none;
    margin: 0 5px;
    display: inline-block;

    &--btn {
      margin-left: 5px;
      padding: 3px;
      border-radius: 50%;
      width: 30px;
      height: 30px !important;
      text-align: center;
      transition: all 0.15s ease;
      color: $green;

      &:hover {
        color: $purple;
        background-color: $white;
      }

      .style-plune & {
        color: $white;

        &:hover {
          color: $pink;
        }
      }
    }

    // &--back-link>a:hover {
    //   text-decoration: underline;
    // }
    &--user {
      border-right: 1px solid $white;
      padding-right: 12px;
    }

    &.save-state {
      margin: 0;
    }

    &.saving {
      color: blue;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  &__style-switcher {
    position: fixed;
    right: 10px;
    top: 95px;
    color: grey;
    text-align: right;
    font-size: 10px;

    button {
      color: grey;
      font-family: inherit;

      &.active {
        text-decoration: underline;
      }

      // color: $green;
    }
  }
}
</style>
