<template>
  <li class="task" :id="task.ID"
    :class="{ 'edited': !displayMode && !generatingPDF, 'page-break-before': values.pageBreak || pageBreak || values.img, 'no-padding': showPlaquette, 'printing': generatingPDF, 'editing': !generatingPDF, 'has-plaquette': values.img, 'no-plaquette': !values.img }">
    <!-- <div class="cats" v-if="!showPlaquette">{{ cats }}</div> -->
    <div class="plaquette" v-if="values.img && showPlaquette">
      <img :src="values.img.url" title="Cliquer pour voir le détail" @click="showPlaquette = false" alt="">
      <a class="qr-link" :href="values.url" target="_blank" v-if="values.url">
        <div ref="qr"></div>
      </a>
      <div v-if="values.descriptionSale" class="description description--sale">
        <div class="description__title">Détail du commercial</div>
        <div v-html="values.descriptionSale"></div>
      </div>
    </div>

    <div class="display" v-if="displayMode && !showPlaquette">
      <div class="left">
        <div class="bg-purple">
          <h3>{{ values.title }}</h3>
          <div class="duration">{{ values.duration | days }} <span
              v-if="values.subscription && values.subscription > 0">
              + abonnement de {{ values.subscription | daysPerYear }}</span></div>
        </div>
      </div>
      <div class="right">
        <div class="description" v-html="values.description"></div>
        <div v-if="values.descriptionSale" class="description description--sale-editable" title="Editer" @click="edit">
          <div class="description__title">Détail du commercial</div>
          <div v-html="values.descriptionSale"></div>
        </div>
        <div v-if="values.descriptionDev && !generatingPDF" class="description description--dev">
          <div class="description__title">Explication des développeurs</div>
          <div v-html="values.descriptionDev"></div>
        </div>
      </div>

      <div class="btns">
        <button class="btn btn-preview" @click="showPlaquette = true" v-if="values.img" title="Voir la version PDF"><i
            class="fa-sharp fa-solid fa-eye"></i></button>
        <a class="btn btn-url" v-if="values.url" :href="values.url" target="_blank" title="Voir la version démo"><i
            class="fa-solid fa-arrow-up-right-from-square"></i></a>
        <button class="btn btn-remove" @click="remove" title="Retirer du plan"><i class="fas fa-trash-alt"></i></button>
        <button class="btn btn-edit" @click="edit" title="Modifier"><i class="fas fa-pen-alt"></i></button>
      </div>
      <div class="label-edited" v-if="values.wasEdited && !generatingPDF">modifié</div>
    </div>

    <div class="edit" v-else-if="!showPlaquette && !generatingPDF">
      <label for="task-title">titre<br />
        <input type="text" id="task-title" v-model="temp.title" />
      </label>
      <br>
      <textarea rows="4" class="description-edit" v-model="temp.description"></textarea>
      <label for="task-title">Détail du commercial (AFFICHE SUR LE PDF FINAL)<br />
        <textarea rows="4" class="description-edit" v-model="temp.descriptionSale"></textarea>
      </label>
      <label for="task-duration">Temps<br />
        <input type="number" id="task-duration" min="0" step="1" v-model="temp.duration" />
      </label><br />
      <label for="task-page-break">
        <input type="checkbox" id="task-page-break" v-model="temp.pageBreak" />
        Saut de page sur le PDF AVANT cette tâche ?
      </label>
      <div class="btns">
        <button class="btn btn-cancel" @click="cancel" title="Annuler"><i class="fas fa-times"></i></button>
        <button class="btn btn-save" @click="save" title="Valider"><i class="fas fa-check"></i></button>
      </div>
    </div>
  </li>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Task",
  data() {
    return {
      values: {
        description: "",
        descriptionDev: "",
        descriptionSale: "",
        title: "",
        duration: 1,
        ID: -1,
        cats: [],
        steps: [],
        wasEdited: false,
        pageBreak: false,
        url: '',
        img: {},
        subscription: 0
      },
      temp: {
        description: "",
        descriptionSale: "",
        title: "",
        duration: 1,
        ID: -1,
        cats: [],
        steps: [],
        wasEdited: false,
        pageBreak: false,
        url: '',
        img: {},
        subscription: 0
      },
      qrcode: null,
      showPlaquette: true,
      displayMode: true,
    };
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    pageBreak: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['generatingPDF']),
    cats() {
      return this.task.cats.join(", ");
    },
  },
  filters: {
    days(val) {
      const day = val > 1 ? 'jours' : 'jour';
      const newVal = `${val} ${day}`;
      return newVal.replace('.', ',');
    },
    daysPerYear(val) {
      const day = val > 1 ? 'jours' : 'jour';
      const newVal = `${val} ${day}/an`;
      return newVal.replace('.', ',');
    }
  },
  methods: {
    remove() {
      this.$store.dispatch("removeFromPlan", this.task.ID);
    },
    edit() {
      this.temp = { ...this.values };
      this.displayMode = false;
    },
    save() {
      if (this.temp.duration == "") {
        this.temp.duration = 0;
      } else if (this.temp.duration < 0) {
        this.temp.duration *= -1;
      }
      this.values = { ...this.temp };
      this.task.duration = this.values.duration ? this.values.duration : 0;

      this.$store.dispatch("updatePlanTask", this.values);
      this.displayMode = true;
      this.values.wasEdited = true;
    },
    cancel() {
      this.displayMode = true;
    },
    generateQRCode() {
      if (this.values.url) {

        setTimeout(() => {
          if (this.qrcode) {
            this.qrcode.clear(); // clear the code.
          }

          this.qrcode = new QRCode(this.$refs.qr, {
            text: this.values.url,
            width: 92,
            height: 92,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H
          });

        }, 750);
      }
    }
  },
  watch: {
    showPlaquette(newVal, oldVal) {
      if (newVal) {
        this.generateQRCode()
      }
    }
  },
  mounted() {
    this.values.ID = this.task.ID;
    this.values.title = this.task.title;
    this.values.description = this.task.description;
    this.values.descriptionDev = this.task.descriptionDev;
    this.values.descriptionSale = this.task.descriptionSale;
    this.values.duration = this.task.duration;
    this.values.cats = this.task.cats;
    this.values.steps = this.task.steps;
    this.values.wasEdited = this.task.wasEdited;
    this.values.pageBreak = this.task.pageBreak;
    this.values.url = this.task.url;
    this.values.img = this.task.img;
    this.values.subscription = this.task.subscription;
    this.showPlaquette = 'undefined' !== typeof this.task.img && this.task.img

    this.generateQRCode()
  }

  // },
};
</script>


<style scoped lang="scss">
@import "../sass/base/variables";

.task {
  border-radius: 5px;
  box-shadow: 2px 4px 18px rgba(#000, 0.15);
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
  padding: 0;
  background-color: $white;

  &.page-break-before:not(.printing) {
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-top: 2px dashed $green;
      pointer-events: none;
    }
  }

  &.printing {
    border-radius: 0;
    margin-bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    // align-items: center;
    background-color: $white;

    margin-top: 30px;

    &.has-plaquette {
      height: 788px;
      margin-top: 0;
    }

    &.no-plaquette.page-break-before {
      margin-top: 0;
    }

    .description {
      margin-bottom: 0;
    }

    .plaquette {
      height: 100%;

      img {
        display: block;
        max-height: 100%;
        object-fit: contain;
      }
    }

    .btns {
      display: none;
    }
  }

  &.no-padding {
    padding: 0;
  }

  .display {
    display: flex;
    width: 100%;

    .left {
      // padding: 15px;
      width: calc(45% - 30px);
      margin-left: 30px;
      // background-color: $purple;
      color: $white;
      text-align: right;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }

    .right {
      padding: 15px;
      border-top: 2px solid $green;
      border-bottom: 2px solid $green;
      width: calc(55% - 30px);
      margin-left: 15px;
    }

  }

  .bg-purple {
    // background: $purple !important;
    padding: 15px;
    width: 100%;
    height: 100%;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    overflow: hidden;

    position: relative;

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      background: $purple !important;
      z-index: 0;
    }


  }

  &.edited:not(.printing) {
    background-color: rgba(#000, .05);
  }

  &.page-break {
    border-bottom: 2px dashed $purple;
  }
}

h3 {
  font-weight: 700;
  font-size: 2.6rem;
  line-height: 1.4;
  margin-bottom: 15px;
  position: relative;
}

.qr-link {
  position: absolute;
  top: 15px;
  left: 15px;
  padding: 3px;
  background-color: $white;

  display: block;
  max-height: 25%;
}

.cats {
  font-style: italic;
  font-size: 1.2rem;
  margin-bottom: 5px;
  opacity: 0.75;
}

.plaquette {
  width: 100%;
  // height: auto;
  cursor: pointer;
  transition: all .25s ease;
  position: relative;

  img {
    display: block;
    width: 100%;

  }

  &:hover {
    filter: brightness(1.1);
  }

  .description--sale {
    margin: 15px;
  }
}

.description {
  margin-bottom: 30px;


  &__title {
    margin-bottom: 5px;
    text-transform: uppercase;
  }

  &--dev {
    background-color: rgba($black, .2);
    padding: 15px;
    font-size: 12px;
  }

  &--sale,
  &--sale-editable {
    border: 1px solid rgba($purple, .7);
    background-color: $white;
    color: $purple;
    padding: 15px;
    font-size: 16px;
  }

  &--sale {
    display: none;
  }

  &--sale-editable {
    transition: all .1s ease;
    cursor: pointer;

    &:hover {
      background-color: rgba($purple, .1)
    }
  }
}

.label-edited {
  position: absolute;
  bottom: 0;
  right: 0;
  color: $white;
  background-color: rgba($purple, .5);
  font-size: 1.1rem;
  padding: 5px 10px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 5px;
}

textarea {
  width: 100%;
  margin-bottom: 10px;
}

input {
  margin-bottom: 10px;
}

hr {
  width: 75px;
  margin-left: 0;
}

.duration {
  font-size: 1.8rem;
  opacity: 0.75;
  color: $green;
  font-weight: 700;
}

.btns {
  position: absolute;
  right: 10px;
  top: 10px;
}

.btn {
  display: inline-block;
  color: $purple;
  padding: 3px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin-left: 8px;
  transition: all .15s ease;
  box-sizing: border-box;

  &:hover {
    color: $white;
    background-color: $purple;
  }

  &-url {
    text-align: center;
    line-height: 17px;
  }

  // &-preview {
  // }

  // &-edit {
  // }

  // &-cancel {
  // }
}
</style>