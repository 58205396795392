<template>
  <div class="home">
    <div class="plan-author">
      <div>Créé par {{ currentPlan.author }} le {{ creationDate }}.<br>Modifié le {{ lastChange }}</div>
    </div>

    <Plan />
    <TaskSelect />
  </div>
</template>

<script>
import TaskSelect from "@/components/TaskSelect.vue";
import Plan from "@/components/Plan.vue";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
    TaskSelect,
    Plan,
  },
  computed: {
    ...mapGetters(["currentPlan"]),
    creationDate() {
      const date = new Date(this.currentPlan.creationDate);
      return date.getDate() + '/' + parseInt(date.getMonth() + 1) + '/' + date.getFullYear();
    },
    lastChange() {
      const date = new Date(this.currentPlan.lastChange);
      return date.getDate() + '/' + parseInt(date.getMonth() + 1) + '/' + date.getFullYear();
    }
  },

  mounted() {
    this.$store.dispatch("fetchTasks");
    this.$store.dispatch("fetchPlans");
  },
};
</script>

<style scoped lang="scss">
.home {
  padding: 50px;
  display: flex;
}

.plan-author {
  position: fixed;
  top: 60px;
  right: 10px;
  color: grey;
  font-size: 10px;
  text-align: right;
}

@media (max-width: 1200px) {
  .home {
    flex-direction: column;
    position: relative;
    padding: 25px;
  }

  .plan-author {
    position: absolute;
    top: 10px;
  }
}

@media (max-width: 768px) {
  .home {
    padding: 15px;
  }
}
</style>