<template>
  <div class="tasks">
    <div class="inner">
      <h2>Actions disponibles</h2>
      <select id="task-select" name="states[]" multiple="multiple" style="width: 100%">
        <optgroup v-for="(cat, index) in tasksByCat" :key="index" :label="index">
          <option v-for="task in tasksByCat[index]" :key="task.ID" :value="task.ID">
            {{ task.post_title }} / {{ task.acf.duration }} jour(s)
          </option>
        </optgroup>
      </select>

      <button @click="setSelection" class="button">Ajouter au plan de com</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tasks",
  computed: {
    tasksByCat() {
      return this.$store.getters.tasksByCat;
    },
    tasksLoaded() {
      return this.$store.getters.tasksLoaded;
    },
  },
  methods: {
    setSelection() {
      // Get selection
      const selection = $("#task-select").select2("data");

      if (selection.length > 0) {
        // Create array of ids
        const taskIds = selection.map((task) => task.id);
        // Update plan
        this.$store.dispatch("addToPlan", taskIds);

        // Clear selection
        $("#task-select").val(null).trigger("change");
      }
    },
  },
  mounted() {
    // Init select2
    $(document).ready(function () {
      $("#task-select").select2({
        placeholder: "Sélectionnez des actions...",
      });
    });
  },
};
</script>

<style scoped lang="scss">
@import "../sass/base/variables";

.tasks {
  width: 400px;
  flex-shrink: 0;
  padding-left: 15px;

  @media (max-width: 1200px) {
    padding-left: 0;

    position: absolute;
    top: 51px;
    right: 25px;
    background-color: $white;
  }

  @media (max-width: 850px) {
    padding-left: 0;
    position: absolute;
    top: 51px;
    width: 300px;
  }

  @media (max-width: 768px) {
    right: 15px;
  }
}

.inner {
  position: sticky;
  top: 100px;
}

.button {
  margin-top: 15px;
  background-color: $green;
  color: $purple;
  border: none;

  .style-plune & {
    background-color: $pink;
    color: $white;
  }
}
</style>